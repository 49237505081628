// src/apis/dashboardApi.js
import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001';

export const fetchUserProfile = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/dashboard`, {
      withCredentials: true,
    });
    console.log(response.data)
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};