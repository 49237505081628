import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CartList from '../components/CartList';
import NavBarFooterDashboard from '../components/NavBarFooterDashboard';
import { fetchCheckoutData } from '../apis/checkoutApi';

const Checkout = () => {
  const [message, setMessage] = useState('');
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => { 
    const fetchData = async () => {  
      try {
        const response = await fetchCheckoutData();
        setMessage(response.message);

        let responseData = response.data;
        setResponseData(responseData);
        
      } catch (error) {
        if (error.status === 401) {
          alert(error.message);
          navigate('/auth');
        } else {
          console.error('Error:', error.message);
        }
      }
    };
    fetchData();
  }, [navigate]);

  return (
    <>
      <CartList data={responseData} />
      <NavBarFooterDashboard />
    </>
  );
};

export default Checkout;