// src/apis/checkoutApi.js
import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001';

export const handleCheckout = async (selectedTimes, selectedBay, selectedDate) => {
    try {
        const response = await axios.post(`${baseURL}/api/checkout`, {
            times: selectedTimes,
            bay: selectedBay,
            date: selectedDate
        }, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const fetchCheckoutData = async () => {
    try {
        const response = await axios.get(`${baseURL}/api/checkout-data`, {
            withCredentials: true,
        });
        console.log("fetching checkout response.data", response.data);
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};

export const confirmBooking = async (cartItems) => {
    try {
        const response = await axios.post(`${baseURL}/api/confirm-booking`, {
            cartItems: cartItems
        }, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        throw error.response.data;
    }
};