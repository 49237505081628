// Dashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeaderDashboard from '../components/HeaderDashboard';
import NavBarFooterDashboard from '../components/NavBarFooterDashboard';
import FacilityList from '../components/FacilityList';

const Facility = () => {

  return (
    <>
      <HeaderDashboard/>
      <FacilityList />
      <NavBarFooterDashboard />
    </>
  );
};

export default Facility;
