import {
  Box,
  SimpleGrid,
  Text,
  Flex
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaCheck } from "react-icons/fa";
import { PiWarningCircleLight } from "react-icons/pi";
import { CalendarIcon } from "@chakra-ui/icons";
import moment from 'moment';
import { fetchBays } from '../apis/baysApi';

export default function ListBays() {
  const [bays, setBays] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const fetchData = async () => {
      let date = new Date();
      let currentHour = date.getHours();
      console.log("current hour is ", currentHour);

      try {
        const response = await fetchBays(currentHour);
        setBays(Array.isArray(response) ? response : []);
        console.log('Bays:', response);
      } catch (error) {
        console.error('Error:', error);
      }
    };
    fetchData();

    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatBookedHours = (startHour, endHour) => {
    if (startHour === null || endHour === null) return '';
    const formattedStart = moment({ hour: startHour }).format('hA');
    const formattedEnd = moment({ hour: endHour }).format('hA');
    return `${formattedStart} - ${formattedEnd}`;
  };

  return (
    <Box maxW="7xl" mx={'auto'} pt={2.5} px={{ base: 2, sm: 12, md: 17 }} paddingBottom={250}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 3, lg: 8 }}>
        {bays.map((bay) => {
          const currentDate = new Date();
          const currentHour = currentDate.getHours();
          const currentMinutes = currentDate.getMinutes();
          const nextAvailableInHours = bay.occupiedStartHour ? bay.occupiedStartHour - currentHour : null;
          const nextAvailableInMinutes = nextAvailableInHours !== null ? (bay.occupiedStartHour * 60 - (currentHour * 60 + currentMinutes)) : null;

          return (
            <Box 
              key={bay.bayId} 
              display="flex" 
              justifyContent="space-between" 
              alignItems="center" 
              border="1px solid #87CEEB" 
              padding={["0.5em", "0.75em", "1em"]} 
              background={bay.isAvailable ? "#d1f1de" : "#d1f1de"}
              borderRadius="10px"
            >
              <Box display="flex" alignItems="center">
                <Box 
                  borderRadius="50%" 
                  width="50px" 
                  height="50px" 
                  border="2px solid" 
                  borderColor={bay.isAvailable ? "#68D391" : "#68D391"} 
                  background={bay.isAvailable ? "#68D391" : "#68D391"} 
                  color="#fff" 
                  display="flex" 
                  flexDirection="column" 
                  justifyContent="center" 
                  alignItems="center" 
                  marginRight="0.5em"
                >
                  <Text fontSize="xs" fontWeight={"bold"}>Bay</Text>
                  <Text fontSize="sm" fontWeight={"bold"}>{bay.bayId}</Text>
                </Box>
                <Box>
                  <Text 
                    color={bay.isAvailable ? "#2C7C58" : "#FF0000"} 
                    fontWeight={"bold"}
                    fontSize={["sm", "md", "lg"]}>
                      {bay.isAvailable ? 'Available' : bay.isBooking ? 'Active Booking' : 'Active Session'}
                  </Text>
                  {!bay.isAvailable && bay.occupiedEndHour !== null && (
                    <>
                      <Text color="#FF0000" fontWeight={"bold"} fontSize={["sm", "md", "lg"]}>
                        Booked Hours: {formatBookedHours(bay.occupiedStartHour, bay.occupiedEndHour)}
                      </Text>
                    </>
                  )}
                  {bay.isAvailable && bay.occupiedEndHour !== null && nextAvailableInHours >= 0 && 
                    <Flex alignItems="center">
                      <CalendarIcon boxSize={4} color={"#2C7C58"} />
                      <Text marginLeft={1.5} color="#2C7C58" fontWeight={"bold"} fontSize={["xs"]}>
                        Next booking {nextAvailableInHours === 0 ? 'is Now' : nextAvailableInHours === 1 ? `in ${nextAvailableInMinutes} minute(s)` : `in ${nextAvailableInHours} hour(s)`}
                      </Text>
                    </Flex>
                  }
                </Box>
              </Box>
              <Box>
                {bay.isAvailable ? <FaCheck color="green" size={'1em'} /> : <PiWarningCircleLight color="red" size={'1.5em'} />}
              </Box>
            </Box>
          )
        })}
      </SimpleGrid>
    </Box>
  )
}