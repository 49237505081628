// src/apis/timeSlotsApi.js
import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001';

export const fetchTimeSlots = async (selectedDateISO, selectedBay) => {
  try {
    const response = await axios.get(`${baseURL}/api/timeslots`, {
      params: {
        date: selectedDateISO,
        bay: selectedBay
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};