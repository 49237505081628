// src/apis/registerApi.js
import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001';

export const register = async (firstname, lastname, phone, email, password) => {
  try {
    const response = await axios.post(`${baseURL}/api/register`, {
      firstname,
      lastname,
      phone,
      email,
      password
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};