import React, { useState, useEffect } from 'react';
import CalendarDate from './CalendarDate';
import BayBooking from './BayBooking';
import TimeSlots from './TimeSlots';

const ParentBookingComponent = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedBay, setSelectedBay] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    //console.log("this is the date", date)
  };

  const handleBayChange = (bay) => {
    setSelectedBay(bay);
    //console.log("this is the bay", bay)
  };

  useEffect(() => {
    if (selectedDate && selectedBay) {
      // Here you can add your logic to fetch data from the backend
    }
  }, [selectedDate, selectedBay]);

  return (
    <>
      <CalendarDate onDateChange={handleDateChange} />
      <BayBooking onBayChange={handleBayChange} />
      <TimeSlots selectedDate={selectedDate} selectedBay={selectedBay} />
    </>
  );
};

export default ParentBookingComponent;