import React from "react";

import { Box, Flex, useBreakpointValue, VStack, StackDivider, Heading, Text, Stack, LinkBox, LinkOverlay, Image } from '@chakra-ui/react';


const ContentHeaderComponent = () => {
    const isMobile = useBreakpointValue({ base: true, md: false });

    if (isMobile) {
      return (
        <Box align="center" justify="center" id="overview">
        <Heading as='h2' size='xl' marginTop={10}>
          Play The Game You Love, On Your Schedule
        </Heading>
        <Text fontSize='lg' marginTop={2}>A members-only indoor golf club experience.</Text>
        </Box>
        )  
    }

  return (
    <Box align="center" justify="center" id="overview">
        <Heading as='h2' size='xl' marginTop={10}>
          Play The Game You Love, On Your Schedule
        </Heading>
        <Text fontSize='lg' marginTop={2}>A members-only indoor golf club experience.</Text>
    </Box>
  );
};
export default ContentHeaderComponent;