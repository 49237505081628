// client/src/index.js
import React from "react";
import { createRoot } from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';
import App from './App';
import theme from './theme';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './AuthContext'; // Import AuthProvider
import { BrowserRouter } from 'react-router-dom';

const root = createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
    <ChakraProvider theme={theme}>
      <AuthProvider> 
        <App />
      </AuthProvider>
    </ChakraProvider>
    </BrowserRouter>
);
reportWebVitals();