import React from "react";

import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  VisuallyHidden,
  HStack,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  CloseButton,
  Image
} from "@chakra-ui/react";

import { Link as ReactRouterLink } from 'react-router-dom';

import { HamburgerIcon } from '@chakra-ui/icons'

import {Link} from 'react-scroll'

const Navbar = () => {
  const bg = useColorModeValue("#87CEEB", "gray.800");
  const bgHamburgerMenu = useColorModeValue("white", "gray.800");
  const mobileNav = useDisclosure();

  return (
    <React.Fragment>
      <chakra.header
        bg={bg}
        color="#fff"
        w="full"
        px={{ base: 2, sm: 20 }}
        py={8}
        //py={4}
        shadow="sm"
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <Flex>
            <chakra.a
              href="/"
              title="The Birdie House Home Page"
              display="flex"
              alignItems="center"
            >
              <Image src="../birdiehouse_logo.svg" width="250px" height="50px" />
              <VisuallyHidden>The Birdie House</VisuallyHidden>
            </chakra.a>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={1}
              mr={1}
              color="#fff"
              display={{ base: "none", md: "inline-flex" }}
            >
              <Link activeClass="active" smooth spy to="overview"><Button variant="ghost">Overview</Button></Link>
              <Link activeClass="active" smooth spy to="pricing"><Button variant="ghost">Pricing</Button></Link>
              <Link activeClass="active" smooth spy to="faqs"><Button variant="ghost">FAQS</Button></Link>
              <Button 
                as={ReactRouterLink}
                to="auth"
                bgColor="#42aaf5" colorScheme="brand" size="sm">
                Sign In
              </Button>
              
            </HStack>
            <Box
              display={{
                base: "inline-flex",
                md: "none",
              }}
            >
              <IconButton
                display={{
                  base: "flex",
                  md: "none",
                }}
                aria-label="Open menu"
                fontSize="20px"
                color="gray.800"
                bgColor="42aaf5"
                _dark={{
                  color: "inherit",
                }}
                variant="ghost"
                icon={<HamburgerIcon />}
                onClick={mobileNav.onOpen}
              />

              <VStack
                pos="absolute"
                top={0}
                left={0}
                right={0}
                display={mobileNav.isOpen ? "flex" : "none"}
                flexDirection="column"
                p={2}
                pb={4}
                m={2}
                bg={bgHamburgerMenu}
                spacing={3}
                rounded="sm"
                shadow="sm"
                zIndex={1}
              >
                <CloseButton
                  aria-label="Close menu"
                  color="black"
                  onClick={mobileNav.onClose}
                />
                <Link activeClass="active" smooth spy to="overview">
                <Button w="full" variant="ghost"
                    borderBottomColor="87CEEB"
                    borderBottom="solid"
                    borderBottomWidth={.2}
                    width="200px"
                >
                  Overview
                </Button>
                </Link>
                <Link activeClass="active" smooth spy to="pricing">
                <Button w="full" variant="ghost"
                borderBottomColor="87CEEB"
                    borderBottom="solid"
                    borderBottomWidth={.2}
                    width="200px">
                  Pricing
                </Button>
                </Link>
                <Button w="full" variant="ghost"
                borderBottomColor="87CEEB"
                    borderBottom="solid"
                    borderBottomWidth={.2}
                    width="200px">
                  FAQS
                </Button>
                <Button w="full" variant="ghost"
                as={ReactRouterLink}
                to="auth">
                  Sign in
                </Button>
              </VStack>
            </Box>
          </HStack>
        </Flex>
      </chakra.header>
    </React.Fragment>
  );
};
export default Navbar;