// src/apis/verifyApi.js
import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_BASE_URL : 'http://localhost:3001';

export const verifyOTP_Register = async (authCode) => {
  try {
    const response = await axios.post(`${baseURL}/api/verify-register`, { authCode }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const verifyOTP_Login = async (authCode) => {
  try {
    const response = await axios.post(`${baseURL}/api/verify-login`, { authCode }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const checkVerificationAuth = async () => {
  try {
    const response = await axios.get(`${baseURL}/api/sessions/verify`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};