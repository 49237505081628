import { useState } from "react";
import {
  Flex,
  Heading,
  Input,
  Button,
  InputGroup,
  Stack,
  InputLeftElement,
  chakra,
  Box,
  Avatar,
  FormControl,
  Text,
} from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { login } from '../apis/loginApi';

const CFaUserAlt = chakra(FaUserAlt);

const Auth = () => {
  const [loginType, setloginType] = useState("sms");
  const [userLogin, setUserLogin] = useState("");
  const navigate = useNavigate();

  const handleUserChange = (e) => {
    let inputValue = e.target.value;
    // Check if the input type is phone
    if (loginType === 'sms') {
      // Remove non-digit characters from the input
      inputValue = inputValue.replace(/\D/g, '');

      // Apply the phone number format
      if (inputValue.length <= 10) {
        setUserLogin(
          inputValue.replace(/(\d{3})(\d{0,3})(\d{0,4})/, (_, p1, p2, p3) => {
            let formattedNumber = p1;
            if (p2) formattedNumber += `-${p2}`;
            if (p3) formattedNumber += `-${p3}`;
            return formattedNumber;
          })
        );
      }
    } else {
      // For other login types, update the userLogin directly
      setUserLogin(inputValue);
    }
  };

  const handleClearInput = () => {
    setUserLogin('');
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(userLogin, loginType);
      if (response.success === true) {
        console.log("Session user for login ", response);
        navigate(response.redirectPath); // Navigate to the path received from the backend
      } else {
        navigate(response.redirectPath);
        alert(response.message);
      }
      // Navigate to the next page or handle the response as needed
    } catch (error) {
      alert(error.message);
      navigate(error.redirectPath)
    }
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="#edf3f8"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mt="-20%"
        justifyContent="center"
        alignItems="center"
      >
        <Avatar bg="#38A169" />
        <Heading color="#38A169">Login Or Sign Up</Heading>
        <Text fontSize="lg">Don't have an account? Just continue below!</Text>
        <Box minW={{ base: "90%", md: "468px" }}>
          <form onSubmit={loginSubmit}>
            <Stack
              p="2rem"
              backgroundColor="whiteAlpha.900"
              boxShadow="md"
              spacing="6"
              height="250px"
            >
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<CFaUserAlt color="gray.300" />}
                  />
                  {loginType === "email" ? (
                    <Input
                      type="email"
                      placeholder="Email Address"
                      value={userLogin}
                      onChange={handleUserChange}
                    />
                  ) : (
                    <Input
                      type="phone"
                      placeholder="Phone Number"
                      value={userLogin}
                      onChange={handleUserChange}
                    />
                  )}
                </InputGroup>
              </FormControl>

              <Button
                borderRadius={0}
                type="submit"
                variant="solid"
                colorScheme="green"
                width="full"
              >
                Login / Sign Up
              </Button>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
};

export default Auth;