// Dashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeaderDashboard from '../components/HeaderDashboard';
import ContentDashboard from '../components/ContentDashboard';
import NavBarFooterDashboard from '../components/NavBarFooterDashboard';

const Dashboard = () => {

  return (
    <>
      <HeaderDashboard/>
      <ContentDashboard />
      <NavBarFooterDashboard />
    </>
  );
};

export default Dashboard;
