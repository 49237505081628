// src/pages/Verify.js
import React, { useState, useEffect } from 'react';
import { Box, Stack, Text, Center, Button, HStack, PinInput, PinInputField } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { checkVerificationAuth, verifyOTP_Login } from '../apis/verifyApi';

const Verify = () => {
  const navigate = useNavigate();
  const [pinValues, setPinValues] = useState(['', '', '', '', '', '']);

  const [message, setMessage] = useState('');

  useEffect(() => { 
    const checkVerificationAuthRequired = async () => {
      try {
        const response = await checkVerificationAuth();
        setMessage(response.data.message);
        
      } catch (error) {
        alert(error.message);
        navigate(error.redirectPath);
      }
    };
    checkVerificationAuthRequired();
  }, [navigate]);

  const handlePinChange = (index, value) => {
    const newPinValues = [...pinValues];
    newPinValues[index] = value;
    setPinValues(newPinValues);
  };

  const handleSubmit = async () => {
    const concatenatedValue = pinValues.join('');
    let authCode = concatenatedValue;

    if (authCode.length === 6) {
      try {
        const response = await verifyOTP_Login(authCode);
        navigate(response.redirectPath); // Change this to your success page
      } catch (error) {
        alert(error.message);
      }
    } else {
      alert("Please enter all digits of the code");
    }
  };

  return (
    <>
      <form action="" className="otp-form" name="otp-form">
        <Box maxW="400px" mx="auto" textAlign="center" fontFamily="">
          <Text fontWeight="bold">Verification Code</Text>
          <Text fontSize="12px" color="#118a44" className="info">
            A verification has been sent to your phone number.
          </Text>
          <Text fontSize="12px" color="initial" textAlign="initial" fontWeight="bold" className="msg">
            Please enter your code to verify
          </Text>
        </Box>
        <Stack direction="row" spacing="10px" justifyContent="center" padding="40px" maxW="400px" mx="auto" margintop="40px" boxShadow="0px 0px 8px 0px #02025044">
          <HStack>
            <PinInput className="pin-input">
              {pinValues.map((value, index) => (
                <PinInputField
                  key={index}
                  value={value}
                  type="tel"
                  onChange={(e) => handlePinChange(index, e.target.value)}
                />
              ))}
            </PinInput>
          </HStack>
        </Stack>
        <Center>
          <Button colorScheme="green" onClick={handleSubmit}>
            Verify
          </Button>
        </Center>
      </form>
    </>
  );
};

export default Verify;