// client/src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Home from './pages/Home'
import Auth from './pages/Auth'
import VerifyRegister from './pages/VerifyRegister';
import VerifyLogin from './pages/VerifyLogin';
import Dashboard from './pages/Dashboard';
import Register from './pages/Register';
import Booking from './pages/Booking';
import Checkout from './pages/Checkout';
import Facility from './pages/Facility';
import Personal from './pages/Personal';
//import HeaderDashboard from './pages/HeaderDashboard';

function App() {
  return (
    <>
    <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Helmet>
    <Routes>
          <Route index element={<Home />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify-register" element={<VerifyRegister />} />
          <Route path="/verify-login" element={<VerifyLogin />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/facility" element={<Facility />} />
          <Route path="/personal" element={<Personal />} />
    </Routes>
    </>
  );
}

export default App;