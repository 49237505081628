import React from "react";
import PersonalHeader from "../components/PersonalHeader";
import NavBarFooterDashboard from '../components/NavBarFooterDashboard';


const Personal = () => {

  return (
      <>
      <PersonalHeader />
      <NavBarFooterDashboard />
      </>
  );
};
export default Personal;