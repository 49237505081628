// Dashboard.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeaderDashboard from '../components/HeaderDashboard';
import NavBarFooterDashboard from '../components/NavBarFooterDashboard';
import CalendarDate from '../components/CalendarDate';
import BayBooking from '../components/BayBooking';
import ParentBookingComponent from '../components/ParentBooking';

const Booking = () => {

  return (
    <>
      <HeaderDashboard />
      <ParentBookingComponent />
      <NavBarFooterDashboard />
    </>
  );
};

export default Booking;
